<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-row class="align-items-center">
          <b-col>
            <h3 class="m-0">Gerenciamento de clientes</h3>
          </b-col>
          <b-col class="text-right">
            <b-button variant="primary" @click="novoCliente"> Novo </b-button>
          </b-col>
        </b-row>
      </b-card-header>

      <b-card-body>
        <b-table
          :items="clientes"
          :fields="fields"
          :busy="loading"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          @filtered="onFiltered"
          show-empty
        >
          <template #empty>
            <div class="text-center">Nenhum cliente encontrado.</div>
          </template>

          <template v-slot:cell(acao)="data">
            <b-dropdown
              variant="clean"
              size="small"
              toggle-class="btn-icon"
              no-caret
            >
              <template #button-content>
                <i class="fas fa-ellipsis-h"></i>
              </template>

              <b-dropdown-item @click="editarCliente(data.item)"
                >Editar</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-table>
      </b-card-body>

      <b-card-footer>
        <div
          class="d-flex justify-content-between align-items-center flex-wrap"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            @change="onPageChange"
            class="m-0"
          ></b-pagination>

          <div class="d-flex align-items-center flex-wrap">
            <b-select v-model="perPage" size="sm" class="mr-4">
              <option
                v-for="option in [20, 50, 100]"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </b-select>

            <span>
              Exibindo de {{ (currentPage - 1) * perPage + 1 }} até
              {{ currentPage * perPage }} de {{ totalRows }} registros.
            </span>
          </div>
        </div>
      </b-card-footer>
    </b-card>

    <cliente-formulario
      ref="mdlClienteFormulario"
      @atualizar="getClientes"
    ></cliente-formulario>
  </div>
</template>

<script>
import ClienteService from "@/core/services/cliente.service";
import ClienteFormulario from "./ClienteFormulario.vue";

export default {
  name: "cliente-listar",

  components: {
    ClienteFormulario,
  },

  data() {
    return {
      clientes: [],
      loading: false,
      fields: [
        {
          key: "razao_social",
          label: "Razão Social",
          sortable: true,
        },
        { key: "telefone", label: "Telefone", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "acao", label: "Ação" },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 20,
      sortBy: "id",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
    };
  },

  mounted() {
    this.getClientes();
  },

  methods: {
    getClientes() {
      this.loading = true;

      ClienteService.getClientes()
        .then(({ data }) => {
          this.clientes = data.clientes;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onPageChange(page) {
      this.currentPage = page;
      this.getUsuarios();
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    novoCliente() {
      this.$refs.mdlClienteFormulario.exibirModal();
    },

    editarCliente(cliente) {
      this.$refs.mdlClienteFormulario.exibirModal(cliente);
    },
  },
};
</script>

<style scoped>
.custom-select-sm {
  height: calc(1.244em + 1.1rem + 2px) !important;
  width: 70px !important;
}
</style>
