import ApiService from "./api.service";
import objectToFormData from "@/helpers";

const ClienteService = {
  async getClientes() {
    return await ApiService.get("/clientes");
  },

  async getCliente(id) {
    return await ApiService.get(`/clientes/${id}`);
  },

  async salvar(cliente) {
    return await ApiService.postFormData(
      "/clientes/salvar",
      objectToFormData(cliente)
    );
  },
};

export default ClienteService;
