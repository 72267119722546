<template>
  <b-modal
    ref="mdlClienteLogomarca"
    no-header
    @hidden="reset"
    footer-class="d-flex justify-content-center"
    body-class="d-flex justify-content-center"
  >
    <b-img :src="src" fluid thumbnail></b-img>

    <template #modal-footer="{ hide }">
      <b-button variant="primary" @click="hide()"> Fechar </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "cliente-logomarca",
  data() {
    return {
      src: null,
    };
  },
  methods: {
    exibirLogomarca(logomarca) {
      const src = URL.createObjectURL(logomarca);
      this.src = src;
      this.$refs.mdlClienteLogomarca.show();
    },

    reset() {
      this.logomarca = null;
    },
  },
};
</script>
